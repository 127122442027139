import { useModalStore } from '~/stores'
export default defineNuxtRouteMiddleware(async (to) => {
    const useModalStoreInstance = useModalStore()
    const { showNoBankModal, showVerifyBankModal } = storeToRefs(
        useModalStoreInstance
    )
    const {
        execute,
        data: userBankAccountsFetched,
        error,
    } = useApi('/payment/indexdeposit', {
        pick: ['data', 'status'],
        immediate: false,
    })
    if (to.path === '/account/withdraw' || to.path === '/account/deposit') {
        await execute()
        // userBankAccounts.value = userBankAccountsFetched.value.data.userBanks
        const hasAtLeastOneEnabled =
            userBankAccountsFetched?.value?.data?.userBanks.some(
                (item) => item.is_disable === false
            )
        if (userBankAccountsFetched?.value?.data?.userBanks?.length === 0) {
            // user need add bank
            showNoBankModal.value = true
            return navigateTo('/account/bank-account')
        } else if (hasAtLeastOneEnabled) {
            // user can continue
            console.log('hasAtLeastOneEnabled')
        } else {
            // user need verify
            showVerifyBankModal.value = true
            return navigateTo('/account/bank-account')
        }
        // in case of error
        if (error.value) {
            console.log(error.value)
        }
    }
})
